	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Slider functionality
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/

// import  './vendor/flickity.min';
// import  './vendor/flickity-fade';
// import './vendor/flickity.pkgd.min';

(function($) {

	$('#home-header > div').each(function () {
        let _this = $(this);
        var args = {
            pageDots: false,
            wrapAround: false,
            draggable: false,
            cellSelector: '.home-header-slide',
            percentPosition: true,
            contain: true,
            setGallerySize: true,
            resize: true,
            imagesLoaded: true,
            cellAlign: 'left',
            freeScroll: false,
            prevNextButtons: false,
            autoPlay: 8000,
            selectedAttraction: 0.008,
            friction: 0.16,
            adaptiveHeight: true,
            fade: true,
            pauseAutoPlayOnHover: false,
            on: {
                ready: function() {
                    this.off( 'uiChange', this.stopPlayer );
                    this.off( 'pointerDown', this.stopPlayer );
                }
            }
        };

        var $carousel = _this.flickity(args);
        $carousel.flickity(args);

	});

})( jQuery );